<template>
  <div class="site-forms-wrapper">
    <div class="site-forms-header">
      <b-button class="request-button" type="is-primary" @click="$router.push({name: 'Sites'})">
        {{ $t('back') }}
      </b-button>

      <router-link class="create-form-button" :to="{ name: 'SiteFormCreate', params: { site_id: $router.currentRoute.params.site_id } }">
        <b-button type="is-success">
          {{ $t('site_forms.create_form') }}
        </b-button>
      </router-link>
    </div>

    <div class="site-forms-content-wrapper">

      <div class="search-wrapper">
        <div class="table-search">
          <input v-model="pagination.search" type="text" @input="searchForms" placeholder="Поиск по названию">
          <div class="table-search-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6084 13.7175L12.1325 10.2416C12.114 10.2232 12.0923 10.2103 12.073 10.1931C12.757 9.15558 13.1561 7.91368 13.1561 6.57813C13.1561 2.94511 10.211 0 6.57807 0C2.94511 0 0 2.94511 0 6.57807C0 10.211 2.94505 13.1561 6.578 13.1561C7.91361 13.1561 9.15545 12.757 10.1929 12.073C10.2101 12.0923 10.223 12.1139 10.2415 12.1324L13.7174 15.6084C14.2396 16.1305 15.0861 16.1305 15.6084 15.6084C16.1305 15.0862 16.1305 14.2396 15.6084 13.7175ZM6.57807 10.8757C4.20446 10.8757 2.28035 8.95161 2.28035 6.57807C2.28035 4.20446 4.20453 2.28035 6.57807 2.28035C8.95155 2.28035 10.8757 4.20453 10.8757 6.57807C10.8757 8.95161 8.95155 10.8757 6.57807 10.8757Z" fill="#2C3236"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="table-loading" v-show="loading">
        <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false"></b-loading>
      </div>
      <div class="forms-empty" v-if="!loading && (!forms || forms.length === 0)">
        {{ $t('no_data') }}
      </div>
      <b-table :data="forms" :mobile-cards="false" v-if="!loading && forms" hoverable>
        <template slot-scope="data">
          <b-table-column label="Название">
            <template slot="header">
              <div class="table-custom-header" @click="selectSortColumn('name')">
                <span>Название</span>

                <svg v-if="filters.name" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 0.384409V15.6163C6 15.8282 5.77049 16 5.48738 16H3.29044C3.00733 16 2.77782 15.8282 2.77782 15.6163V6.73635C2.77782 6.52443 2.54831 6.35264 2.2652 6.35264H0.513481C0.104054 6.35264 -0.140152 6.01109 0.0869565 5.7561L5.06086 0.171569C5.34219 -0.144308 6 0.0047715 6 0.384409Z" :fill="pagination.sort_field === 'name' ? '#7957d5' : '#2C3236'"/>
                  <rect width="8" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 16)" :fill="pagination.sort_field == 'name' ? '#7957d5' : '#2C3236'"/>
                  <rect width="6" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 12)" :fill="pagination.sort_field == 'name' ? '#7957d5' : '#2C3236'"/>
                  <rect width="4" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 8)" :fill="pagination.sort_field == 'name' ? '#7957d5' : '#2C3236'"/>
                  <rect width="2" height="2" rx="1" transform="matrix(1 0 0 -1 8 4)" :fill="pagination.sort_field == 'name' ? '#7957d5' : '#2C3236'"/>
                </svg>
                <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 15.6156V0.383701C6 0.171788 5.77049 0 5.48738 0H3.29044C3.00733 0 2.77782 0.171788 2.77782 0.383701V9.26365C2.77782 9.47557 2.54831 9.64736 2.2652 9.64736H0.513481C0.104054 9.64736 -0.140152 9.98891 0.0869565 10.2439L5.06086 15.8284C5.34219 16.1443 6 15.9952 6 15.6156Z" :fill="pagination.sort_field === 'name' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" width="8" height="1" rx="0.5" :fill="pagination.sort_field == 'name' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="4" width="6" height="1" rx="0.5" :fill="pagination.sort_field == 'name' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="8" width="4" height="1" rx="0.5" :fill="pagination.sort_field == 'name' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="12" width="2" height="2" rx="1" :fill="pagination.sort_field == 'name' ? '#7957d5' : '#2C3236'"/>
                </svg>
              </div>
            </template>
            {{ data.row.name }}
          </b-table-column>

          <b-table-column label="Активность" width="150" :centered="true">
            <template slot="header">
              <div class="table-custom-header" @click="selectSortColumn('active')">
                <span>Активность</span>

                <svg v-if="filters.active" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 0.384409V15.6163C6 15.8282 5.77049 16 5.48738 16H3.29044C3.00733 16 2.77782 15.8282 2.77782 15.6163V6.73635C2.77782 6.52443 2.54831 6.35264 2.2652 6.35264H0.513481C0.104054 6.35264 -0.140152 6.01109 0.0869565 5.7561L5.06086 0.171569C5.34219 -0.144308 6 0.0047715 6 0.384409Z" :fill="pagination.sort_field === 'active' ? '#7957d5' : '#2C3236'"/>
                  <rect width="8" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 16)" :fill="pagination.sort_field == 'active' ? '#7957d5' : '#2C3236'"/>
                  <rect width="6" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 12)" :fill="pagination.sort_field == 'active' ? '#7957d5' : '#2C3236'"/>
                  <rect width="4" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 8)" :fill="pagination.sort_field == 'active' ? '#7957d5' : '#2C3236'"/>
                  <rect width="2" height="2" rx="1" transform="matrix(1 0 0 -1 8 4)" :fill="pagination.sort_field == 'active' ? '#7957d5' : '#2C3236'"/>
                </svg>
                <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 15.6156V0.383701C6 0.171788 5.77049 0 5.48738 0H3.29044C3.00733 0 2.77782 0.171788 2.77782 0.383701V9.26365C2.77782 9.47557 2.54831 9.64736 2.2652 9.64736H0.513481C0.104054 9.64736 -0.140152 9.98891 0.0869565 10.2439L5.06086 15.8284C5.34219 16.1443 6 15.9952 6 15.6156Z" :fill="pagination.sort_field === 'active' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" width="8" height="1" rx="0.5" :fill="pagination.sort_field == 'active' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="4" width="6" height="1" rx="0.5" :fill="pagination.sort_field == 'active' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="8" width="4" height="1" rx="0.5" :fill="pagination.sort_field == 'active' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="12" width="2" height="2" rx="1" :fill="pagination.sort_field == 'active' ? '#7957d5' : '#2C3236'"/>
                </svg>
              </div>
            </template>

            <div>
              <b-switch 
                :value="data.row.active"
                type="is-success"
                disabled
              >
              </b-switch>
            </div>
          </b-table-column>

          <b-table-column label="Дата добавления" width="190" :centered="true">
            <template slot="header">
              <div class="table-custom-header" @click="selectSortColumn('time_added')">
                <span>Дата добавления</span>

                <svg v-if="filters.time_added" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 0.384409V15.6163C6 15.8282 5.77049 16 5.48738 16H3.29044C3.00733 16 2.77782 15.8282 2.77782 15.6163V6.73635C2.77782 6.52443 2.54831 6.35264 2.2652 6.35264H0.513481C0.104054 6.35264 -0.140152 6.01109 0.0869565 5.7561L5.06086 0.171569C5.34219 -0.144308 6 0.0047715 6 0.384409Z" :fill="pagination.sort_field === 'time_added' ? '#7957d5' : '#2C3236'"/>
                  <rect width="8" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 16)" :fill="pagination.sort_field == 'time_added' ? '#7957d5' : '#2C3236'"/>
                  <rect width="6" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 12)" :fill="pagination.sort_field == 'time_added' ? '#7957d5' : '#2C3236'"/>
                  <rect width="4" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 8)" :fill="pagination.sort_field == 'time_added' ? '#7957d5' : '#2C3236'"/>
                  <rect width="2" height="2" rx="1" transform="matrix(1 0 0 -1 8 4)" :fill="pagination.sort_field == 'time_added' ? '#7957d5' : '#2C3236'"/>
                </svg>
                <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 15.6156V0.383701C6 0.171788 5.77049 0 5.48738 0H3.29044C3.00733 0 2.77782 0.171788 2.77782 0.383701V9.26365C2.77782 9.47557 2.54831 9.64736 2.2652 9.64736H0.513481C0.104054 9.64736 -0.140152 9.98891 0.0869565 10.2439L5.06086 15.8284C5.34219 16.1443 6 15.9952 6 15.6156Z" :fill="pagination.sort_field === 'time_added' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" width="8" height="1" rx="0.5" :fill="pagination.sort_field == 'time_added' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="4" width="6" height="1" rx="0.5" :fill="pagination.sort_field == 'time_added' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="8" width="4" height="1" rx="0.5" :fill="pagination.sort_field == 'time_added' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="12" width="2" height="2" rx="1" :fill="pagination.sort_field == 'time_added' ? '#7957d5' : '#2C3236'"/>
                </svg>
              </div>
            </template>
            <div>
              {{ formatDate(data.row.time_added) }}
            </div>
          </b-table-column>

          <b-table-column label="Подписка" width="130" :centered="true">
            <template slot="header">
              <div class="table-custom-header" @click="selectSortColumn('subscription')">
                <span>Подписка</span>

                <svg v-if="filters.subscription" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 0.384409V15.6163C6 15.8282 5.77049 16 5.48738 16H3.29044C3.00733 16 2.77782 15.8282 2.77782 15.6163V6.73635C2.77782 6.52443 2.54831 6.35264 2.2652 6.35264H0.513481C0.104054 6.35264 -0.140152 6.01109 0.0869565 5.7561L5.06086 0.171569C5.34219 -0.144308 6 0.0047715 6 0.384409Z" :fill="pagination.sort_field === 'subscription' ? '#7957d5' : '#2C3236'"/>
                  <rect width="8" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 16)" :fill="pagination.sort_field == 'subscription' ? '#7957d5' : '#2C3236'"/>
                  <rect width="6" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 12)" :fill="pagination.sort_field == 'subscription' ? '#7957d5' : '#2C3236'"/>
                  <rect width="4" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 8)" :fill="pagination.sort_field == 'subscription' ? '#7957d5' : '#2C3236'"/>
                  <rect width="2" height="2" rx="1" transform="matrix(1 0 0 -1 8 4)" :fill="pagination.sort_field == 'subscription' ? '#7957d5' : '#2C3236'"/>
                </svg>
                <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 15.6156V0.383701C6 0.171788 5.77049 0 5.48738 0H3.29044C3.00733 0 2.77782 0.171788 2.77782 0.383701V9.26365C2.77782 9.47557 2.54831 9.64736 2.2652 9.64736H0.513481C0.104054 9.64736 -0.140152 9.98891 0.0869565 10.2439L5.06086 15.8284C5.34219 16.1443 6 15.9952 6 15.6156Z" :fill="pagination.sort_field === 'subscription' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" width="8" height="1" rx="0.5" :fill="pagination.sort_field == 'subscription' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="4" width="6" height="1" rx="0.5" :fill="pagination.sort_field == 'subscription' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="8" width="4" height="1" rx="0.5" :fill="pagination.sort_field == 'subscription' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="12" width="2" height="2" rx="1" :fill="pagination.sort_field == 'subscription' ? '#7957d5' : '#2C3236'"/>
                </svg>
              </div>
            </template>

            <div>
              <b-switch 
                :value="data.row.subscription"
                type="is-success"
                disabled
              >
              </b-switch>
            </div>
          </b-table-column>

          <b-table-column label="Banner disabler" width="180" :centered="true">
            <template slot="header">
              <div class="table-custom-header" @click="selectSortColumn('banner_disabler')">
                <span>Banner disabler</span>

                <svg v-if="filters.banner_disabler" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 0.384409V15.6163C6 15.8282 5.77049 16 5.48738 16H3.29044C3.00733 16 2.77782 15.8282 2.77782 15.6163V6.73635C2.77782 6.52443 2.54831 6.35264 2.2652 6.35264H0.513481C0.104054 6.35264 -0.140152 6.01109 0.0869565 5.7561L5.06086 0.171569C5.34219 -0.144308 6 0.0047715 6 0.384409Z" :fill="pagination.sort_field === 'banner_disabler' ? '#7957d5' : '#2C3236'"/>
                  <rect width="8" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 16)" :fill="pagination.sort_field == 'banner_disabler' ? '#7957d5' : '#2C3236'"/>
                  <rect width="6" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 12)" :fill="pagination.sort_field == 'banner_disabler' ? '#7957d5' : '#2C3236'"/>
                  <rect width="4" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 8)" :fill="pagination.sort_field == 'banner_disabler' ? '#7957d5' : '#2C3236'"/>
                  <rect width="2" height="2" rx="1" transform="matrix(1 0 0 -1 8 4)" :fill="pagination.sort_field == 'banner_disabler' ? '#7957d5' : '#2C3236'"/>
                </svg>
                <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 15.6156V0.383701C6 0.171788 5.77049 0 5.48738 0H3.29044C3.00733 0 2.77782 0.171788 2.77782 0.383701V9.26365C2.77782 9.47557 2.54831 9.64736 2.2652 9.64736H0.513481C0.104054 9.64736 -0.140152 9.98891 0.0869565 10.2439L5.06086 15.8284C5.34219 16.1443 6 15.9952 6 15.6156Z" :fill="pagination.sort_field === 'banner_disabler' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" width="8" height="1" rx="0.5" :fill="pagination.sort_field == 'banner_disabler' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="4" width="6" height="1" rx="0.5" :fill="pagination.sort_field == 'banner_disabler' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="8" width="4" height="1" rx="0.5" :fill="pagination.sort_field == 'banner_disabler' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="12" width="2" height="2" rx="1" :fill="pagination.sort_field == 'banner_disabler' ? '#7957d5' : '#2C3236'"/>
                </svg>
              </div>
            </template>

            <div>
              <b-switch 
                :value="data.row.banner_disabler"
                type="is-success"
                disabled
              >
              </b-switch>
            </div>
          </b-table-column>

          <b-table-column label="Basket finale" width="160" :centered="true">
            <template slot="header">
              <div class="table-custom-header" @click="selectSortColumn('basket_finale')">
                <span>Basket finale</span>

                <svg v-if="filters.basket_finale" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 0.384409V15.6163C6 15.8282 5.77049 16 5.48738 16H3.29044C3.00733 16 2.77782 15.8282 2.77782 15.6163V6.73635C2.77782 6.52443 2.54831 6.35264 2.2652 6.35264H0.513481C0.104054 6.35264 -0.140152 6.01109 0.0869565 5.7561L5.06086 0.171569C5.34219 -0.144308 6 0.0047715 6 0.384409Z" :fill="pagination.sort_field === 'basket_finale' ? '#7957d5' : '#2C3236'"/>
                  <rect width="8" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 16)" :fill="pagination.sort_field == 'basket_finale' ? '#7957d5' : '#2C3236'"/>
                  <rect width="6" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 12)" :fill="pagination.sort_field == 'basket_finale' ? '#7957d5' : '#2C3236'"/>
                  <rect width="4" height="1" rx="0.5" transform="matrix(1 0 0 -1 8 8)" :fill="pagination.sort_field == 'basket_finale' ? '#7957d5' : '#2C3236'"/>
                  <rect width="2" height="2" rx="1" transform="matrix(1 0 0 -1 8 4)" :fill="pagination.sort_field == 'basket_finale' ? '#7957d5' : '#2C3236'"/>
                </svg>
                <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 15.6156V0.383701C6 0.171788 5.77049 0 5.48738 0H3.29044C3.00733 0 2.77782 0.171788 2.77782 0.383701V9.26365C2.77782 9.47557 2.54831 9.64736 2.2652 9.64736H0.513481C0.104054 9.64736 -0.140152 9.98891 0.0869565 10.2439L5.06086 15.8284C5.34219 16.1443 6 15.9952 6 15.6156Z" :fill="pagination.sort_field === 'basket_finale' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" width="8" height="1" rx="0.5" :fill="pagination.sort_field == 'basket_finale' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="4" width="6" height="1" rx="0.5" :fill="pagination.sort_field == 'basket_finale' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="8" width="4" height="1" rx="0.5" :fill="pagination.sort_field == 'basket_finale' ? '#7957d5' : '#2C3236'"/>
                  <rect x="8" y="12" width="2" height="2" rx="1" :fill="pagination.sort_field == 'basket_finale' ? '#7957d5' : '#2C3236'"/>
                </svg>
              </div>
            </template>

            <div>
              <b-switch 
                :value="data.row.basket_finale"
                type="is-success"
                disabled
              >
              </b-switch>
            </div>
          </b-table-column>

          <b-table-column label="Редактировать" :centered="true">
            <router-link :to="{ name: 'SiteFormEdit', params: { site_id: $router.currentRoute.params.site_id, form_id: data.row.id } }">
              <img style="width: 20px; height: 20px;" src="data:image/svg+xml;base64,
                PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzgzLjk0NyAzODMuOTQ3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzODMuOTQ3IDM4My45NDc7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PGc+PGc+Cgk8Zz4KCQk8Zz4KCQkJPHBvbHlnb24gcG9pbnRzPSIwLDMwMy45NDcgMCwzODMuOTQ3IDgwLDM4My45NDcgMzE2LjA1MywxNDcuODkzIDIzNi4wNTMsNjcuODkzICAgICIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojNzk1N0Q1IiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wb2x5Z29uPgoJCQk8cGF0aCBkPSJNMzc3LjcwNyw1Ni4wNTNMMzI3Ljg5Myw2LjI0Yy04LjMyLTguMzItMjEuODY3LTguMzItMzAuMTg3LDBsLTM5LjA0LDM5LjA0bDgwLDgwbDM5LjA0LTM5LjA0ICAgICBDMzg2LjAyNyw3Ny45MiwzODYuMDI3LDY0LjM3MywzNzcuNzA3LDU2LjA1M3oiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6Izc5NTdENSIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiPjwvcGF0aD4KCQk8L2c+Cgk8L2c+CjwvZz48L2c+IDwvc3ZnPg==" />
            </router-link>
          </b-table-column>
        </template>
      </b-table>

      <div class="table-pagination">
        <div class="table-pagination-length">
          Показать
          <b-select v-model="pagination.per_page">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </b-select>
        </div>
        <div class="table-pagination-arrow">
          <b-button :disabled="!pagination.has_prev" class="left-arrow" type="is-primary" @click="prevPage">
            <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 18.7071C-0.0976311 18.3166 -0.0976311 17.6834 0.292893 17.2929L7.58579 10L0.292893 2.70711C-0.0976309 2.31658 -0.0976309 1.68342 0.292893 1.29289C0.683418 0.902369 1.31658 0.902369 1.70711 1.29289L10.4142 10L1.70711 18.7071C1.31658 19.0976 0.683417 19.0976 0.292893 18.7071Z" fill="#ffffff"/>
              </g>
            </svg>
          </b-button>
          <b-button :disabled="!pagination.has_next" class="right-arrow" type="is-primary" @click="nextPage"> 
            <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 18.7071C-0.0976311 18.3166 -0.0976311 17.6834 0.292893 17.2929L7.58579 10L0.292893 2.70711C-0.0976309 2.31658 -0.0976309 1.68342 0.292893 1.29289C0.683418 0.902369 1.31658 0.902369 1.70711 1.29289L10.4142 10L1.70711 18.7071C1.31658 19.0976 0.683417 19.0976 0.292893 18.7071Z" fill="#ffffff"/>
              </g>
            </svg>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

export default {
  name: 'SiteForms',
  data () {
    return {
      loading: false,
      pagination: {
        has_prev: false,
        has_next: false,
        page: 1,
        per_page: 10,
        sort_field: 'name',
        sort_direction: 'asc',
        search: ''
      },
      filters: {
        name: true,
        active: false,
        time_added: false,
        banner_disabler: false,
        basket_finale: false,
        subscription: false
      },
      forms: null,
      search_timer_request: null
    }
  },
  created () {
    this.getSiteForms()
  },
  watch: {
    'pagination.per_page': function () {
      this.pagination.page = 1
      this.getSiteForms()
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    getSiteForms() {
      this.loading = true
      this.$store.dispatch('getSiteForms', {
        pagination: this.pagination,
        vue: this,
        callback: this.setSiteForms
      })
    },
    setSiteForms (data) {
      this.pagination.has_prev = data.pagination.has_prev
      this.pagination.has_next = data.pagination.has_next
      this.forms = data.forms
      this.loading = false
    },
    selectSortColumn (value) {
      this.filters[value] = !this.filters[value]
      this.pagination.sort_field = value

      if(this.filters[value]) {
        this.pagination.sort_direction = 'asc'
      }
      else {
        this.pagination.sort_direction = 'desc'
      }
      this.getSiteForms()
    },
    searchForms () {
      clearTimeout(this.search_timer_request)
      this.search_timer_request = null

      this.search_timer_request = setTimeout(function() {
        this.getSiteForms()
      }.bind(this), 1500)
    },
    prevPage () {
      if(!this.pagination.has_prev) return
      this.pagination.page--
      this.getSiteForms()
    },
    nextPage () {
      if(!this.pagination.has_next) return
      this.pagination.page++
      this.getSiteForms()
    },
  }
}
</script>

<style lang="scss">
.site-forms-wrapper {
  position: relative;

  .site-forms-header {

    button:first-child {
      margin-right: 15px;
    }
    .create-form-button {
      display: inline-block;
      width: 135px;
    }
  }
  .site-forms-content-wrapper {
    position: relative;

    .loading-overlay {
      width: 100%;
      min-height: 300px;
    }
    .site-setings-content {
      margin-top: 20px;
      border-radius: 4px;
      padding: 15px;
      border: 1px solid #dbdbdb;

      .select-input {
        width: 250px;
      }
      .language-wrapper {
        height: 80px;
      }
      .settings-title {
        margin-bottom: 25px;
      }
      .collect-approvement, .regex-cart-wrapper {
        margin-bottom: 20px;
      }
      .is-active, .regex-phone-wrapper {
        margin-bottom: 15px;
      }
      .language-title, .domain-title, .regex-phone-title, .regex-cart-title {
        margin-bottom: 5px;
      }
      .regex-phone-wrapper, .regex-cart-wrapper {
        width: 400px;
      }

      .domain-name-wrapper {
        margin-bottom: 20px;

        input {
          width: 250px;
        }
      }
    }
    .b-table {
      .table-custom-header {
        cursor: pointer;

        span {
          padding-right: 10px;
        }
      }
    }
    .table-pagination {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #dbdbdb;
      padding-top: 20px;
      border-width: 2px 0 0px;
      margin-top: 15px;

      .table-pagination-length {
        display: flex;
        align-items: center;

        .control {
          margin-left: 5px;
        }
      }
      .left-arrow {
        margin-right: 3px;
        svg {
          transform: rotate(180deg)
        }
      }
      span {
        display: flex;
      }
    }
    .table-loading {
      min-height: 300px;

      .loading-overlay {
        position: relative;
      }
    }
    .search-wrapper {
      display: flex;
      justify-content: flex-end;

      .table-search {
        display: flex;
        width: 220px;
        margin-bottom: 20px;

        input {
          width: 100%;
          padding: 0 10px;
          border-radius: 4px 0 0 4px;
          border: 1px solid black;
          border-right: none;
          outline: none;
        }
        .table-search-icon {
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          border: 1px solid black;
          border-radius: 0 4px 4px 0px;
        }
      }
    }
    .forms-empty {
      width: 100%;
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
  }
  .modal-content {
    width: 500px !important;

    .modal-card-body {
      position: relative;
      border-radius: 3px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .custom-close-modal {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
      .modal-buttons {
        button:first-child {
          margin-right: 20px;
        }
      }
    }
  }
  .notification-wrapper {
    position: absolute;
    right: 30px;
    top: 10px;
    z-index: 100;
  }
}
</style>